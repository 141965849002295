import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

function NewsSimple() {
    return (
        <div className="portfolio-related-work ptb--120" id="news">
            <div className="about-wrapper p-4 p-md-0">
                <div className="container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <span className="primary-subtitle">Novice in obvestila</span>
                                    <h2 className="title">Aktualne novice iz litijskega podzemlja</h2>
                                </div>
                                <div className="row mt--30">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Valvazorjev trg, nedelja, 1. december, 16:30
                                                            </p>
                                                            <h3>PRIŽIG LUČK, MIKLAVŽEV SEJEM</h3>
                                                            <p className="mb-15px">
                                                                Čarobnost lučk, pozitivno vzdušje ob pestrem dogajanju v
                                                                mestu, Miklavževe dobrote na obloženih stojnicah na
                                                                Valvazorjevem trgu, igrivost in veselje ob pričakovanju
                                                                novega leta.
                                                            </p>
                                                            <p className="mb-15px font-weight-bold">
                                                                Prižig lučk v mestu bo popestrila tolkalna skupina
                                                                Perkakšns, ogledali si boste lahko ognjeno predstavo
                                                                Šola za zmaje.
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/prizig-luck-2.jpg"
                                                                    alt="Prižig lučk"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Valvazorjev trg / Rudnik Sitarjevec Litija, nedelja,
                                                                1. december, 18:00
                                                            </p>
                                                            <h3>NOČNI POHOD Z BAKLAMI DO RUDNIKA SITARJEVEC</h3>
                                                            <p className="mb-15px font-weight-bold">
                                                                Ob 18. uri se bomo iz starega mestnega jedra pohodniki z
                                                                baklami v roki odpravili do Rudnika Sitarjevec Litija.
                                                                Na ta dan bo obisk Izvoznega rova rudnika brezplačen.
                                                            </p>
                                                            <p className="mb-15px">
                                                                Pred pohodom boste pohodniki na stojnici TIC Litija
                                                                prevzeli zaporedno številko vstopa v rudnik, prevzeli
                                                                bakle in si jih tudi prižgali. Vstopi skupin v rudnik se
                                                                bodo vrstili z 10 minutnim zamikom. Za animacijo bodo
                                                                popestrili škratje Sitarjevčki. Pohoda se udeležite na
                                                                lastno odgovornost.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/pohod.jpg"
                                                                    alt="Nočni pohod z baklami"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Valvazorjev trg, petek, 20. december, 16:30–20:00
                                                            </p>
                                                            <h3>BOŽIČNI SEJEM</h3>
                                                            <p className="mb-15px">
                                                                <strong>V petek, 20. 12. popoldne, od 16:30 do 20:00 bo
                                                                    Valvazorjev trg še posebno
                                                                    čaroben. </strong> Božični sejem bo dodatek k
                                                                prazničnemu vzdušju v mestu. Ponudniki na stojnicah bodo
                                                                poskrbeli, da bo pestrost ponudbe privabila slehernega
                                                                obiskovalca, ki bo lahko izbiral med prazničnimi
                                                                kulinaričnimi dobrotami in unikatnimi izdelki,
                                                                primernimi za obdarovanje. Čarobnost Litije bo nadgradil
                                                                sejemski kulturni program.
                                                            </p>
                                                            <ul className="list-group list-group-flush mt-3">
                                                                <li className="list-group-item d-flex align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: '#f9f9f9',
                                                                        borderTopLeftRadius: '7px',
                                                                        borderTopRightRadius: '7px',
                                                                        marginBottom: '0'
                                                                    }}>
                                                                    <span
                                                                        className="badge bg-primary me-3">16:30–17:15</span>
                                                                    <span className="ml-2">Učenci Glasbene Šole Litija, MePZ KUD Jevnica</span>
                                                                </li>
                                                                <li className="list-group-item d-flex align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: '#f9f9f9',
                                                                        marginBottom: '0'
                                                                    }}>
                                                                    <span className="badge bg-primary me-3">17:30</span>
                                                                    <span className="ml-2">PIKA PIŠE, predstava s prihodom Dedka Mraza (Enostavno prijatelji)</span>
                                                                </li>
                                                                <li className="list-group-item d-flex  align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: '#f9f9f9',
                                                                        borderBottomLeftRadius: '7px',
                                                                        borderBottomRightRadius: '7px',
                                                                        marginBottom: '0'
                                                                    }}>
                                                                    <span className="badge bg-primary me-3">18:30</span>
                                                                    <span className="ml-2">Koncert Pihalnega orkestra Litija</span>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/sejem.jpg"
                                                                    alt="Božični sejem"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Tržnica pred Občino Litija, sobota, 21. december,
                                                                8:00–12:00
                                                            </p>
                                                            <h3>BOŽIČNI SEJEM</h3>
                                                            <p className="mb-15px">
                                                                <strong>V soboto, 21. december, dopoldne, od 8:00 do
                                                                    12:00 bo na tržnici pred Občino Litija
                                                                    čarobno</strong>. Ponudniki na stojnicah bodo
                                                                poskrbeli, da bo pestrost ponudbe privabila slehernega
                                                                obiskovalca, ki bo lahko izbiral med prazničnimi
                                                                kulinaričnimi dobrotami in unikatnimi izdelki,
                                                                primernimi za obdarovanje. Čarobnost Litije bo nadgradil
                                                                sejemski kulturni program.
                                                            </p>
                                                            <ul className="list-group list-group-flush mt-3">
                                                                <li className="list-group-item d-flex align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: '#f9f9f9',
                                                                        borderRadius: '7px',
                                                                        marginBottom: '0'
                                                                    }}>
                                                                    <span className="badge bg-primary me-3">9:30 in 10:30</span>
                                                                    <span
                                                                        className="ml-2">Učenci Glasbene šole Bučar</span>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/bozicni-sejem-3.jpg"
                                                                    alt="Božični sejem dan 2"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Rudnik Sitarjevec, 26. - 30. december, 16:00 - 19:00
                                                            </p>
                                                            <h3>MINERALNE JASLICE V RUDNIKU SITARJEVEC LITIJA</h3>
                                                            <p className="mb-15px">
                                                                V tednu med Božičem in novim letom vas vabimo pred in v
                                                                Rudnik Sitarjevec Litija, ki je eden najbogatejših
                                                                mineralnih rudnikov v Evropi. <strong>Obiskovalci si
                                                                boste v teh dneh brezplačno ogledali mineralne jaslice v
                                                                Izvoznem rovu. Ogledi v skrajšani obliki bodo potekali
                                                                med 16:00 in 19:00.</strong> Od Božiča pa vse do
                                                                svečnice bodo v rudniku razstavljene edinstvene jaslice
                                                                iz kamnin in mineralov rudnika Sitarjevec. Postavitelj
                                                                letošnjih mineralnih jaslic bo Miha Kos.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/mineralne-jaslice.jpg"
                                                                    alt="Mineralne jaslice"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Rudnik Sitarjevec, 28. december, 18:00 - 19:00
                                                            </p>
                                                            <h3>KONCERT V RUDNIKU SITARJEVEC LITIJA</h3>
                                                            <p className="mb-15px">
                                                                Brezplačne oglede jaslic v rudniku bo v soboto, 28. 12
                                                                ob 18:00 in 19:00 s koncertom popestril MVS Lipa.
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/koncert-1.jpg"
                                                                    alt="Animirane jaslice"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Rudnik Sitarjevec, 29. december, 18:00 - 19:00
                                                            </p>
                                                            <h3>KONCERT V RUDNIKU SITARJEVEC LITIJA</h3>
                                                            <p className="mb-15px">
                                                                Brezplačne oglede jaslic v rudniku bo v nedeljo, 29. 12
                                                                ob 18:00 in 19:00 s koncertom popestrila vokalna skupina
                                                                TRIAD.
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/koncert-2.jpg"
                                                                    alt="Animirane jaslice"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p className="text-primary text-uppercase mb--0 fontWeight400"
                                                               style={{"fontSize": "0.875rem"}}>
                                                                Rudnik Sitarjevec, četrtek, 26. december, 17:00
                                                            </p>
                                                            <h3>ANIMIRANE JASLICE PRED RUDNIKOM SITARJEVEC</h3>
                                                            <p className="mb-15px">
                                                                Prisluhnite božični zgodbi animiranih jaslic v pesku v
                                                                izvedbi Irene Režek ob spremljavi vokalne skupine ŽVS
                                                                Lipa Litija in pripovedovalke Maje Morela. Ogled
                                                                Izvoznega rova rudnika je brezplačen.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/animirane-jaslice.png"
                                                                    alt="Animirane jaslice"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsSimple;
